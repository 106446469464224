<template>
  <el-button
    v-if="showBackToTop"
    class="custom-back-to-top"
    round
    @click="scrollTo"
    >{{ $t('footer.back_to_top') }} <i class="el-icon-top"></i
  ></el-button>
</template>

<script>
export default {
  name: 'LadBackToTop',
  data() {
    return {
      showBackToTop: false, // Inicialmente oculto
    }
  },
  mounted() {
    // Agregar el event listener para el evento de scroll
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    // Eliminar el event listener antes de destruir el componente
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      // Actualizar el estado de showBackToTop basado en la posición del scroll
      this.showBackToTop = window.scrollY > 150
    },
    scrollTo() {
      this.$scrollTo('.app-main', 500, {
        offset: 0,
      })
    },
  },
}
</script>

<style lang="scss">
.custom-back-to-top {
  color: black;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  // add fixed button
  bottom: 10px;
  position: fixed;
  right: 15px;
  z-index: 2000;

  @media (min-width: 992px) {
    // remove fixed button
    display: inline-block;
    position: relative;
    right: unset;
    bottom: unset;
    z-index: unset;
  }
}

body.page-with-drawer--landing,
body.page-with-drawer--experience {
  .app-wrapper {
    .custom-back-to-top {
      bottom: 95px;
    }
  }
}
</style>
